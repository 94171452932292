<template>
  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.69933 4.70937L11.2993 0.109375L12.8906 1.70068L6.69933 7.89197L0.508026 1.70067L2.09933 0.109375L6.69933 4.70937Z"
      fill="black"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(13);
  }
</style>

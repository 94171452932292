<template>
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" title="instagram">
    <path
      d="M9.42821 1.7166C11.9455 1.7166 12.2441 1.72612 13.2388 1.77215C15.7938 1.88961 16.9873 3.11417 17.1036 5.67597C17.1491 6.6799 17.1578 6.98148 17.1578 9.52424C17.1578 12.0678 17.1483 12.3686 17.1036 13.3725C16.9865 15.9319 15.7962 17.1589 13.2388 17.2763C12.2441 17.3223 11.9471 17.3319 9.42821 17.3319C6.91088 17.3319 6.61232 17.3223 5.61843 17.2763C3.0571 17.1581 1.86993 15.928 1.75365 13.3717C1.70808 12.3678 1.69865 12.067 1.69865 9.52344C1.69865 6.98068 1.70886 6.6799 1.75365 5.67518C1.87071 3.11417 3.06102 1.88882 5.61843 1.77136C6.6131 1.72612 6.91088 1.7166 9.42821 1.7166ZM9.42821 0C6.86766 0 6.5471 0.0111107 5.54143 0.0571406C2.11742 0.215865 0.214492 2.13484 0.0573549 5.59661C0.0109996 6.61324 0 6.93703 0 9.52344C0 12.1098 0.0109996 12.4344 0.0565692 13.4503C0.213706 16.9089 2.11349 18.831 5.54064 18.9897C6.5471 19.0358 6.86766 19.0469 9.42821 19.0469C11.9888 19.0469 12.3101 19.0358 13.3158 18.9897C16.7366 18.831 18.6443 16.912 18.7991 13.4503C18.8454 12.4344 18.8564 12.1098 18.8564 9.52344C18.8564 6.93703 18.8454 6.61324 18.7998 5.5974C18.6458 2.14198 16.7437 0.216658 13.3166 0.0579343C12.3101 0.0111107 11.9888 0 9.42821 0V0ZM9.42821 4.63315C6.75452 4.63315 4.58682 6.82275 4.58682 9.52344C4.58682 12.2241 6.75452 14.4145 9.42821 14.4145C12.1019 14.4145 14.2696 12.2249 14.2696 9.52344C14.2696 6.82275 12.1019 4.63315 9.42821 4.63315ZM9.42821 12.6979C7.69263 12.6979 6.28547 11.2773 6.28547 9.52344C6.28547 7.77033 7.69263 6.34896 9.42821 6.34896C11.1638 6.34896 12.5709 7.77033 12.5709 9.52344C12.5709 11.2773 11.1638 12.6979 9.42821 12.6979ZM14.4613 3.29749C13.8359 3.29749 13.3291 3.80938 13.3291 4.4403C13.3291 5.07123 13.8359 5.58312 14.4613 5.58312C15.0859 5.58312 15.5919 5.07123 15.5919 4.4403C15.5919 3.80938 15.0859 3.29749 14.4613 3.29749Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(19);
  }
</style>

import moment from 'moment-timezone';

// --

export default () => {
  function format(date) {
    return moment(date).format('MMM D, YYYY');
  }

  function formatTime(date) {
    return moment(date).format('HH:mm');
  }

  function formatEvent(startDate, endDate) {
    if (!hasStarted(startDate) || longAgo(endDate)) {
      return format(startDate);
    }

    return moment(endDate).fromNow();
  }

  function isLive(startDate, endDate) {
    return moment().isBetween(startDate, endDate);
  }

  function hasStarted(startDate) {
    return moment().isSameOrAfter(startDate);
  }

  function hasEnded(endDate) {
    return moment().isAfter(endDate);
  }

  function longAgo(date) {
    return moment().diff(date, 'hours') > 48;
  }

  // --

  function formatNews(date) {
    if (longAgo(date)) {
      return format(date);
    }

    return moment(date).fromNow();
  }

  function getCountdown(date) {
    if (hasStarted(date)) return { days: 0, hours: 0, minutes: 0 };

    const diff = moment(date).diff(moment());
    const duration = moment.duration(diff);

    return { days: Math.floor(duration.asDays()), hours: duration.hours(), minutes: duration.minutes() };
  }

  function guessTimezone() {
    return moment.tz(moment.tz.guess()).zoneAbbr();
  }

  // --
  return { format, formatTime, formatEvent, formatNews, isLive, hasStarted, hasEnded, longAgo, getCountdown, guessTimezone };
};

<template>
  <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg" title="youtube">
    <path
      d="M21.9941 6.64559C22.0435 4.90078 21.7307 3.16903 21.0847 1.61113C20.6464 0.971885 20.0381 0.540496 19.3658 0.392131C16.5852 0.084365 13.7931 -0.0417782 11.0014 0.0142419C8.21995 -0.0443208 5.43788 0.0777519 2.66703 0.379942C2.11922 0.501496 1.61225 0.814931 1.20801 1.282C0.308609 2.29377 0.208676 4.02475 0.108743 5.48754C-0.0362477 8.11761 -0.0362477 10.7566 0.108743 13.3867C0.137654 14.21 0.23815 15.0272 0.408542 15.8246C0.529037 16.4403 0.772821 17.0099 1.11807 17.4825C1.52506 17.9743 2.04383 18.3056 2.60707 18.4333C4.76154 18.7577 6.93239 18.8921 9.10272 18.8356C12.6004 18.8965 15.6683 18.8356 19.2959 18.4943C19.873 18.3744 20.4063 18.0427 20.8249 17.5434C21.1046 17.202 21.3136 16.7841 21.4345 16.3244C21.7918 14.9867 21.9674 13.5867 21.9541 12.1798C21.9941 11.4972 21.9941 7.37699 21.9941 6.64559ZM8.74296 12.9112V5.36564L14.659 9.15673C13.0001 10.2782 10.8116 11.546 8.74296 12.9112Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(22);
  }
</style>

<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" title="tiktok">
    <g clip-path="url(#clip0_912_17421)">
      <path
        d="M13.5571 0H10.3792V12.8442C10.3792 14.3746 9.15699 15.6317 7.63596 15.6317C6.11494 15.6317 4.89269 14.3746 4.89269 12.8442C4.89269 11.3412 6.08778 10.1114 7.5545 10.0568V6.83204C4.32231 6.88668 1.71484 9.53752 1.71484 12.8442C1.71484 16.1783 4.37663 18.8564 7.66314 18.8564C10.9496 18.8564 13.6114 16.1509 13.6114 12.8442V6.25813C14.8065 7.13265 16.2732 7.65188 17.8214 7.67922V4.45449C15.4312 4.3725 13.5571 2.40487 13.5571 0Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_912_17421">
        <rect width="18.8564" height="18.8564" fill="white" transform="translate(0.144531)" />
      </clipPath>
    </defs>
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(19);
  }
</style>

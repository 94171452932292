<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" title="facebook">
    <path
      d="M18.8564 9.52345C18.8564 4.26379 14.6353 0 9.42821 0C4.22115 0 0 4.26379 0 9.52345C0 14.2769 3.44775 18.2168 7.95505 18.9312V12.2763H5.56117V9.52345H7.95505V7.42531C7.95505 5.03849 9.36261 3.7201 11.5162 3.7201C12.5478 3.7201 13.6267 3.9061 13.6267 3.9061V6.24976H12.4378C11.2666 6.24976 10.9014 6.98387 10.9014 7.73701V9.52345H13.5162L13.0982 12.2763H10.9014V18.9312C15.4087 18.2168 18.8564 14.2769 18.8564 9.52345Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(19);
  }
</style>

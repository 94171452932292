<script setup>
  import IconFacebook from '~/components/icons/social/IconFacebook.vue';
  import IconInstagram from '~/components/icons/social/IconInstagram.vue';
  import IconSnapchat from '~/components/icons/social/IconSnapchat.vue';
  import IconTikTok from '~/components/icons/social/IconTikTok.vue';
  import IconTwitter from '~/components/icons/social/IconTwitter.vue';
  import IconYouTube from '~/components/icons/social/IconYouTube.vue';

  // --

  const { type, url } = defineProps(['type', 'url']);

  // --

  const icons = {
    facebook: IconFacebook,
    instagram: IconInstagram,
    snapchat: IconSnapchat,
    tiktok: IconTikTok,
    twitter: IconTwitter,
    youtube: IconYouTube,
  };
  const icon = computed(() => icons[type]);
</script>

<!----------------------------------------------------------------------------->

<template>
  <NuxtLink :to="url" target="_blank"><component :is="icon" /></NuxtLink>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  a {
    display: block;

    width: px(20);
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    :deep(svg) {
      path {
        @include fade('fill', 0.77s);
      }
    }

    @include hover {
      :deep(svg) {
        path {
          fill: $white !important;
          @include fade('fill', 0.15s);
        }
      }
    }
  }
</style>

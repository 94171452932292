import { getTimeZones } from '@vvo/tzdb';
import moment from 'moment-timezone';

export default () => {
  const guessedTimezone = moment.tz.guess();
  for (const timezone of getTimeZones()) {
    if (guessedTimezone === timezone.name) {
      return timezone.countryCode;
    }
  }
  return '-';
};

import { defineStore } from 'pinia';
import { collectionList, statamicRequest } from '~/pinkyellow/satanic-api';

export const usePpvChannelsStore = defineStore('ppv-channel', {
  state: () => ({
    channels: {},
    countries: [],
  }),

  getters: {
    getChannels(state) {
      return (country) => {
        return state.channels[country] ? state.channels[country].channels : [];
      };
    },

    getCountries(state) {
      return state.countries;
    },

    getCountry(state) {
      return (country) => {
        return state.countries.find((x) => x.value === country);
      };
    },
  },

  actions: {
    async fetch() {
      if (this.countries.length) {
        return;
      }

      let data = await statamicRequest({ uri: 'ppv-channels' });

      if (!data) {
        this.channels = {};
        this.countries = [];
        return;
      }

      for (const [country, channels] of Object.entries(data.channels)) {
        this.channels[country] = { channels: channels };
      }

      data.countries.forEach((country) => {
        this.countries.push(country);
      });
    },
  },
});

<template>
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" title="x">
    <path
      d="M11.222 8.06499L18.2417 0H16.5783L10.4831 7.00282L5.61492 0H0L7.36164 10.5894L0 19.0469H1.66359L8.10033 11.6517L13.2415 19.0469H18.8564L11.2218 8.06499H11.2223H11.222ZM8.94352 10.6826L8.1976 9.6282L2.26291 1.23767H4.81793L9.60742 8.00908L10.3533 9.06349L16.579 17.8653H14.024L8.94368 10.6829V10.6824L8.94352 10.6826Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(19);
  }
</style>

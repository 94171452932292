<script setup>
  const props = defineProps(['socials']);

  const socials = props.socials || [
    {
      type: 'twitter',
      url: 'https://twitter.com/GLORY_WS',
    },
    {
      type: 'instagram',
      url: 'https://www.instagram.com/glorykickboxing/',
    },
    {
      type: 'facebook',
      url: 'https://www.facebook.com/GLORYKICKBOXINGOFFICIAL/',
    },
    {
      type: 'youtube',
      url: 'https://www.youtube.com/user/gloryworldseries',
    },
    {
      type: 'snapchat',
      url: 'https://www.snapchat.com/add/glorykickboxing',
    },
    {
      type: 'tiktok',
      url: 'https://www.tiktok.com/@glory_fights',
    },
  ];
</script>

<!----------------------------------------------------------------------------->

<template>
  <div>
    <ul>
      <li v-for="social in socials">
        <Social :type="social.type.value || social.type" :url="social.url" />
      </li>
    </ul>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  ul {
    @include hitbox;
    cursor: pointer;

    @include hover {
      :deep(svg) {
        path {
          fill: $white-40;
        }
      }
    }

    display: inline-flex;
    align-items: center;

    gap: px(32);
    height: px(20);

    li {
      height: 100%;
    }

    @include break-phone {
      width: 100%;

      gap: 1rem;
      justify-content: space-between;
    }
  }
</style>
